import React from 'react';

import { FaHtml5,FaReact,FaNodeJs,FaJava,FaPython,FaAws,FaDocker,FaFigma } from "react-icons/fa"
import { IoLogoJavascript,IoLogoCss3 } from "react-icons/io"
import { SiCplusplus,SiPhp,SiSolidity,SiMongodb,SiMysql,SiExpress,SiTypescript } from "react-icons/si"
import { TbBrandGolang } from "react-icons/tb"
import { BsGit } from "react-icons/bs"
import { BiLogoFigma } from "react-icons/bi"



const Skills = () => {

    const skills = [
        {
            id: 1,
            child: (
                <>
                <FaHtml5 size={30} color="#FA5026"></FaHtml5>
                </>    
            ),
            title: 'HTML'
        },
        {
            id: 2,
            child: (
                <>
                <IoLogoJavascript size={30} color="yellow"></IoLogoJavascript>
                </>    
            ),
            title: 'Javascript'
        },
        {
            id: 3,
            child: (
                <>
                <IoLogoCss3 size={30} color="#2672FA"></IoLogoCss3>
                </>    
            ),
            title: 'CSS'
        },
        {
            id: 4,
            child: (
                <>
                <FaReact size={30} color="#26F4FA"></FaReact>
                </>    
            ),
            title: 'React.js'
        },
        {
            id: 5,
            child: (
                <>
                <SiTypescript size={30} color="#2788CC"></SiTypescript>
                </>    
            ),
            title: 'Typescript'
        },
        {
            id: 6,
            child: (
                <>
                <SiExpress size={30} color="#FFFFFF"></SiExpress>
                </>    
            ),
            title: 'Express.js'
        },
        {
            id: 7,
            child: (
                <>
                <FaNodeJs size={30} color="#27CC54"></FaNodeJs>
                </>    
            ),
            title: 'Node.js'
        },
        {
            id: 8,
            child: (
                <>
                <FaJava size={30} color="#598CCB"></FaJava>
                </>    
            ),
            title: 'Java'
        },
        {
            id: 9,
            child: (
                <>
                <SiCplusplus size={30} color="#7BABE6"></SiCplusplus>
                </>    
            ),
            title: 'C++'
        },
        {
            id: 10,
            child: (
                <>
                <TbBrandGolang size={30} color="#7BDBE6"></TbBrandGolang>
                </>    
            ),
            title: 'Go'
        },
        {
            id: 11,
            child: (
                <>
                <SiPhp size={30} color="#9087A4"></SiPhp>
                </>    
            ),
            title: 'PHP'
        },
        {
            id: 12,
            child: (
                <>
                <FaPython size={30} color="#FDEB0D"></FaPython>
                </>    
            ),
            title: 'Python'
        },
        {
            id: 13,
            child: (
                <>
                <SiSolidity size={30} color="#DDDDDD"></SiSolidity>
                </>    
            ),
            title: 'Solidity'
        },
        {
            id: 14,
            child: (
                <>
                <SiMongodb size={30} color="#72F38D"></SiMongodb>
                </>    
            ),
            title: 'MongoDB'
        },
        {
            id: 15,
            child: (
                <>
                <SiMysql size={30} color="#4D6FAD"></SiMysql>
                </>    
            ),
            title: 'MySQL'
        },
        {
            id: 16,
            child: (
                <>
                <FaAws size={30} color="#FFA907"></FaAws>
                </>    
            ),
            title: 'AWS'
        },
        {
            id: 17,
            child: (
                <>
                <FaDocker size={30} color="#0788FF"></FaDocker>
                </>    
            ),
            title: 'Docker'
        },
        {
            id: 18,
            child: (
                <>
                <BsGit size={30} color="#F36932"></BsGit>
                </>    
            ),
            title: 'Git'
        },
        {
            id: 19,
            child: (
                <>
                <BiLogoFigma size={30} color="#FFFFFF"></BiLogoFigma>
                </>    
            ),
            title: 'Figma'
        },
        
    ]

  return (
    <div name="skills" className="pb-20 bg-gradient-to-b from-slate-900 to-gray-950 w-full h-full">
        <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
            <div>
                <p className="pb-2 text-4xl font-bold border-b-4 border-gray-500 inline">Skills</p>
            </div>
            <div className="w-full grid grid-cols-3 sm:grid-cols-5 gap-8 text-center py-8 px-12 sm:px-0">
                {
                    skills.map(({id, src, child, title}) => (
                    <div key={id} className={`hover:scale-105 duration-500 py-4 place-self-center`}>
                        <div className="flex justify-center">
                            {child}
                        </div>
                        <img src={src} alt="" className="w-20 mx-auto"/>
                        <p className="mt-4">{title}</p>
                    </div>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default Skills