import React from 'react'
import inventorySystem from '../assets/portfolio/inventorySystem.png'
import emedicalSystem from '../assets/portfolio/emedicalSystem.png'
import { BsGithub } from 'react-icons/bs'


const Projects = () => {

  const projects = [
    {
      id: 1,
      src: inventorySystem,
      title: "Inventory System",
      href: "https://github.com/vcyhk/InventorySystem",
    },
    {
      id: 2,
      src: emedicalSystem,
      title: "E-medical System",
      href: "https://github.com/vcyhk/e-medical",
    }
  ]

  return (
    <div name="projects" className="pb-20 bg-gradient-to-b from-gray-950 to-slate-900 w-full text-white md:h-full">
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-12">
          <p className="pb-2 text-4xl font-bold inline border-b-4 border-gray-500">Projects</p>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-2">
          {projects.map(({id, src, title, href}) =>
              <a href={href} key={id} target="_blank" rel="noreferrer">
                <div className="bg-gray-800 rounded-lg duration-500 hover:bg-slate-700 ">
                  <img src={src} alt="" className="rounded-md "/>
                  <div className="flex items-center justify-center h-full">
                    <BsGithub></BsGithub><button className="px-2 py-3 m-4">{title}</button>
                  </div>
                </div>
              </a>
          )}
        </div>

      </div>
    </div>
  )
}

export default Projects