import React, { useState } from 'react';
import { FaBars,  FaTimes } from 'react-icons/fa';
import { Link } from 'react-scroll';

const NavBar = () => {

    const [nav, setNav] = useState(false)

    const links = [
        {
            id: 1,
            link: 'home',
        },
        {
            id: 2,
            link: 'experiences'
        },
        {
            id: 3,
            link: 'projects'
        },
        {
            id: 4,
            link: 'skills'
        },
    ]

    return (
        <div className="flex justify-center items-center w-full h-20 text-white fixed bg-black px-4">
            <ul className="hidden md:flex items-center">
                {links.map(({ id, link }) => (
                    <li key={id} className="px-4 cursor-pointer capitalize font-medium text-gray-300 hover:scale-105 duration-200">
                        <Link to={link} smooth duration={500}>{link}</Link>
                    </li>
                ))}
                <a href="/resume.pdf" target="_blank" className="ml-2 px-4 py-2 cursor-pointer capitalize font-medium text-emerald-500 hover:scale-105 duration-200 border border-emerald-500">Resume</a>
            </ul>

            <div onClick={() => setNav(!nav)} className="cursor-pointer pr-4 z-10 text-gray-300 md:hidden">
                {nav ? <FaTimes size={30}/> : <FaBars size={30}/>}
            </div>
            
            {nav && (
                <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800 text-gray-300">
                    {links.map(({ id, link }) => (
                        <li key={id} className='px-4 cursor-pointer capitalize py-6'>
                            <Link onClick={() => setNav(!nav)} to={link} smooth duration={500}>{link}</Link>
                        </li>
                    ))}
                    <a href="/resume.pdf" target="_blank" className="ml-2 px-4 py-2 cursor-pointer capitalize font-medium text-emerald-500 hover:scale-105 duration-200 border border-emerald-500">Resume</a>
                </ul>
            )}
        </div>
    )
}

export default NavBar