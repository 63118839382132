import React from 'react';
import { RiArrowRightSLine } from 'react-icons/ri'
import { BsLinkedin,BsGithub } from 'react-icons/bs'
import { IoMdMail } from 'react-icons/io'
import { Link } from 'react-scroll';

const Home = () => {

    const social = [
        {
            id: 1,
            child: (
                <>
                <BsLinkedin size={30} color="#D3D3D3"></BsLinkedin>
                </>    
            ),
            href: "https://www.linkedin.com/in/vitochan115/",
        },
        {
            id: 2,
            child: (
                <>
                <BsGithub size={30} color="#D3D3D3"></BsGithub>
                </>    
            ),
            href: "https://github.com/vcyhk/",
        },
        {
            id: 3,
            child: (
                <>
                <IoMdMail size={30} color="#D3D3D3"></IoMdMail>
                </>    
            ),
            href: "mailto:vitovitochan@gmail.com",
        },
    ]

  return (
    <div name="home" className="py-26 h-screen w-full bg-gradient-to-b from-gray-950 bg-slate-900">
        <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
            {/* <div className="flex flex-col justify-center">
                <img src={ProPic} alt="profile" className="rounded-2xl px-5 mx-auto w-2/3 md:w-full hover:scale-110 duration-300"/>
            </div> */}

            <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center ">
                <h2 className="text-3xl m-5 sm:text-5xl font-bold text-white">
                    Hello, I'm Vito Chan.
                </h2>
                <p className="text-gray-500 py-6 max-w-md text-center"> 
                    Computer science graduate with a passion for software and web development. I am a highly motivated and hard-working Developer with an aptitude to learn new programming languages. 
                </p>
                <div className="flex">
                    {
                        social.map(({id, child, href}) => (
                        <div key={id} className={`hover:scale-105 duration-500 py-3 pr-4`}>
                            <a href={href} target="_blank" rel="noreferrer">
                                {child}
                            </a>
                        </div>
                        ))
                    }
                </div>
                
                <div className="flex gap-4">
                    <Link to="projects" smooth duration="500" className="group text-white w-fit px-6 py-3 my-6 flex items-center rounded-md bg-gradient-to-r from-emerald-500 to-emerald-300 cursor-pointer">
                        Projects 
                        <span className="group-hover:rotate-90 duration-300">
                            <RiArrowRightSLine size={25} className="ml-1"/>
                        </span>
                    </Link>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Home