import React from 'react'

const Experiences = () => {
  const experiences = [
    {
      id: 1,
      title: "Research Assistant",
      company: "Hong Kong Metropolitan University",
      date: "Aug 2022 - Apr 2023",
      detail: "Developed advanced adaptive Blockchain system. Researched and designed IoT solutions for Blockchain in health-care application. Explored data and organised project work progress. Conducted experimental research as instructed by the Investigator. Performed data analysis and assisted in report writing and presentation of the result."
    },
    {
      id: 2,
      title: "Programmer",
      company: "Social Enterprise Innovations Limited",
      date: "Jun 2021 - Sep 2021",
      detail: "Designed and developed user-friendly websites. Modified databases and table structures for websites. Created eCommerce websites with WordPress for clients. Executed SEO strategies for websites. Fixed bugs from existing websites and implemented enhancements.",
    },
  ]
  return (
    <div name="experiences" className="pb-20 w-full h-full bg-gradient-to-b from-slate-900 to-gray-950 text-white">
        <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full">
            <div className="pb-8">
                <p className="pb-2 text-4xl font-bold inline border-b-4 border-gray-500">Experiences</p>
            </div>
            {
              experiences.map(({id, title, company, date, detail}) => (
                <div key={id} className="hover:bg-slate-800 duration-500 p-5 rounded-md mb-5">
                  <p className="text-xl">{title}</p>
                  <p>{company}</p>
                  <p className="pb-3">{date}</p>
                  <p>
                    {detail}
                  </p>
                </div>
              ))
            }         
        </div>
    </div>
  )
}

export default Experiences